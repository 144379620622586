import { useCallback, useEffect, useRef } from 'react';
import { ResolvablePromise } from '@halo-data/utilities';
import type { ILeaderboardProvider } from '../../lib/leaderboard';

const callMap = new Map<
  number,
  ResolvablePromise<
    Awaited<ReturnType<ILeaderboardProvider[keyof ILeaderboardProvider]>>
  >
>();
export function useWorkerLeaderboard() {
  const workerRef = useRef<Worker>();

  const callLeaderboardProviderFn = useCallback(
    <TFunction extends keyof ILeaderboardProvider>(
      fn: TFunction,
      args: Parameters<ILeaderboardProvider[TFunction]>
    ): ReturnType<ILeaderboardProvider[TFunction]> => {
      const callId = Math.random();
      const abort = () => {
        workerRef.current?.postMessage({ callId, cancel: true });
      };
      const promise = new ResolvablePromise<
        Awaited<ReturnType<ILeaderboardProvider[keyof ILeaderboardProvider]>>
      >();
      callMap.set(callId, promise);
      switch (fn) {
        case 'getGamertagIndex':
          args[3]?.addEventListener('abort', abort);
          promise.finally(() => args[3]?.removeEventListener('abort', abort));
          // Signal cannot be transmitted, remove it from arg list
          args = args.slice(0, 3) as Parameters<
            ILeaderboardProvider[TFunction]
          >;
          break;
      }
      workerRef.current?.postMessage({
        callId,
        fn,
        args,
      });
      return promise as ReturnType<ILeaderboardProvider[TFunction]>;
    },
    []
  );

  useEffect(() => {
    workerRef.current = new Worker(new URL('./worker.ts', import.meta.url));
    const messageHandler = (
      event: MessageEvent<
        | {
            callId: number;
            result: Awaited<
              ReturnType<ILeaderboardProvider[keyof ILeaderboardProvider]>
            >;
          }
        | {
            callId: number;
            error: unknown;
            forceReload: boolean;
          }
      >
    ): void => {
      const promise = callMap.get(event.data.callId);
      if (!promise) {
        throw new Error('Promise not found');
      }
      if ('result' in event.data) {
        promise.resolve(event.data.result);
      } else {
        if (typeof event.data.error === 'string') {
          event.data.error = JSON.parse(event.data.error);
        }
        if (event.data.forceReload) {
          // Prevent infinite reload loop
          const searchParams = new URLSearchParams(location.search);
          if (!searchParams.has('force-reload')) {
            searchParams.set('force-reload', '1');
            location.search = searchParams.toString();
          }
        }
        promise.reject(event.data.error);
      }
      callMap.delete(event.data.callId);
    };
    workerRef.current.addEventListener('message', messageHandler);

    return () => {
      workerRef.current?.removeEventListener('message', messageHandler);
      workerRef.current?.terminate();
    };
  }, []);

  return {
    callLeaderboardProviderFn,
  };
}
