'use client';
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { requestRetryPolicy } from '@halo-data/utilities';
import { Privacy } from 'halo-infinite-api';
import NextLink from 'next/link';
import { useEffect } from 'react';
import { haloInfiniteClient } from '../lib/clients';
import { useCurrentUserGamertag } from '../lib/hooks/current-user';
import { localStorageEvent } from '../lib/local-storage/event-based-localstorage';

export default function PrivacyWarningModal() {
  const currentUser = useCurrentUserGamertag();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const ignore = localStorageEvent.getItem('privacy-warning');
    if (ignore === 'ignore' || !currentUser) {
      return;
    }

    const abortController = new AbortController();
    requestRetryPolicy
      .execute(
        (ctx) => haloInfiniteClient.getCurrentUser({ signal: ctx.signal }),
        abortController.signal
      )
      .then(({ xuid }) => {
        requestRetryPolicy
          .execute(
            (ctx) =>
              haloInfiniteClient.getMatchesPrivacy(xuid, {
                signal: ctx.signal,
              }),
            abortController.signal
          )
          .then((privacy) => {
            if (
              privacy.MatchmadeGames === Privacy.Hide ||
              privacy.OtherGames === Privacy.Hide
            ) {
              onOpen();
            }
          });
      })
      .catch((e) => {
        if (!(e instanceof DOMException && e.name === 'AbortError')) {
          throw e;
        }
      });
    return () => {
      abortController.abort();
    };
  }, [onOpen, currentUser]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Match Privacy Warning</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Your current match privacy settings may prevent Halo Query (and
            everyone else) from showing all your matches.
          </Text>
          <Text mt={2}>
            You can change your privacy settings at{' '}
            <Link
              as={NextLink}
              href="https://www.halowaypoint.com/settings/privacy"
              isExternal
              target="_blank"
            >
              https://www.halowaypoint.com/settings/privacy
            </Link>
            .
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              localStorageEvent.setItem('privacy-warning', 'ignore');
            }}
          >
            Don&apos;t show this again
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
