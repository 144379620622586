'use client';
import { List, ListItem } from '@chakra-ui/react';
import { Observable } from 'rxjs';
import PlayerSearchResult from './player-search-result';
import { useObservable } from '../../lib/hooks/use-observable';

export const ResultsList = (props: {
  results$: Observable<
    {
      xuid: string;
      gamertag: string;
      displayPicRaw: string | undefined;
    }[]
  >;
  onResultClick?: (gamertag: string) => void;
}) => {
  const results = useObservable(props.results$, []);

  return results.length ? (
    <List
      spacing={3}
      padding={3}
      position={'absolute'}
      backgroundColor={'gray.500'}
      flexGrow={1}
      width="100%"
      zIndex={10000}
    >
      {results.map((user) => (
        <ListItem key={user.xuid}>
          <PlayerSearchResult
            user={user}
            onResultClick={() => props.onResultClick?.(user.gamertag)}
          />
        </ListItem>
      ))}
    </List>
  ) : null;
};
