import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Dexie from 'dexie';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Observable, Subject } from 'rxjs';
import { appInsights } from '../../lib/application-insights/client';
import { ILeaderboardProvider, LeaderboardEntry } from '../../lib/leaderboard';
import {
  ensureJoin,
  selfId as hiveMindSelfId,
  peerStatus$,
  requestEntries,
  sendLeaderboardEntriesToAllPeers,
} from '../../lib/leaderboard/hive-mind';
import { HiveMindContext, type useHiveMind } from './hive-mind-context';
import { LeaderboardContext } from './leaderboard-context';
import { useWorkerLeaderboard } from './worker-leaderboard';

export default function LeaderboardProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { callLeaderboardProviderFn } = useWorkerLeaderboard();
  const newEntries$ = useMemo(() => new Subject<LeaderboardEntry[]>(), []);
  const leaderboardProvider = useMemo<
    ILeaderboardProvider & { newEntries$: Observable<LeaderboardEntry[]> }
  >(
    () => ({
      ...(Object.fromEntries(
        (
          [
            'getAllEntries',
            'getRandomEntry',
            'getGamertagIndex',
            'getSkillBuckets',
            'getRankedEntries',
            'getPlaylistAssetIds',
          ] as const
        ).map((fn) => [
          fn,
          (...args: Parameters<ILeaderboardProvider[typeof fn]>) =>
            callLeaderboardProviderFn(fn, args),
        ]) as [
          keyof ILeaderboardProvider,
          ILeaderboardProvider[keyof ILeaderboardProvider]
        ][]
      ) as {
        [k in keyof ILeaderboardProvider]: ILeaderboardProvider[keyof ILeaderboardProvider];
      } as unknown as ILeaderboardProvider),
      async getPlaylistEntriesCount(playlistAssetId) {
        const count = await callLeaderboardProviderFn(
          'getPlaylistEntriesCount',
          [playlistAssetId]
        );
        if (count === 0) {
          requestEntries();
        }
        return count;
      },
      async addLeaderboardEntries(entries) {
        const entriesAdded = await callLeaderboardProviderFn(
          'addLeaderboardEntries',
          [entries]
        ).catch((err) => {
          if (err instanceof Dexie.DexieError) {
            appInsights.trackTrace({
              severityLevel: SeverityLevel.Warning,
              message: err.message,
            });
          } else {
            appInsights.trackException({
              exception: err,
            });
          }

          throw err;
        });

        if (entriesAdded.length) {
          newEntries$.next(entriesAdded);
          sendLeaderboardEntriesToAllPeers(entriesAdded);
        }
        return entriesAdded;
      },
      newEntries$: newEntries$.asObservable(),
    }),
    [callLeaderboardProviderFn, newEntries$]
  );

  const [hiveMindProvider, setHiveMindProvider] =
    useState<ReturnType<typeof useHiveMind>>();
  useEffect(() => {
    ensureJoin(leaderboardProvider);
    setHiveMindProvider({ selfId: hiveMindSelfId, peerStatus$ });
  }, [leaderboardProvider]);

  return (
    <LeaderboardContext.Provider value={leaderboardProvider}>
      <HiveMindContext.Provider value={hiveMindProvider}>
        {children}
      </HiveMindContext.Provider>
    </LeaderboardContext.Provider>
  );
}
