import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Link,
  Spacer,
} from '@chakra-ui/react';
import NextLink from 'next/link';

export default function PlayerSearchResult({
  user,
  onResultClick,
}: {
  user: { gamertag: string; displayPicRaw: string | undefined };
  onResultClick?: () => void;
}) {
  return (
    <Flex flexWrap="wrap" rowGap="20px">
      <Flex justifyContent="flex-start" flexGrow={1}>
        <Avatar src={user.displayPicRaw} />
        <Flex flexGrow={1} ml={2} flexDirection={'column'}>
          <Spacer />
          <Box>{user.gamertag}</Box>
          <Spacer />
        </Flex>
      </Flex>
      <Flex justifyContent={'flex-end'} flexDirection={'column'}>
        <Spacer />
        <Flex justifyContent={'flex-end'}>
          <ButtonGroup>
            <Link
              as={NextLink}
              href={'/matches?gamertag=' + user.gamertag}
              onClick={() => onResultClick && onResultClick()}
            >
              <Button type="button">Matches</Button>
            </Link>
            <Link
              as={NextLink}
              href={'/players/' + user.gamertag}
              onClick={() => onResultClick && onResultClick()}
            >
              <Button type="button">Profile</Button>
            </Link>
            <Link
              as={NextLink}
              href={'/leaderboard?gamertag=' + user.gamertag}
              onClick={() => onResultClick && onResultClick()}
            >
              <Button type="button">Leaderboard</Button>
            </Link>
          </ButtonGroup>
        </Flex>
        <Spacer />
      </Flex>
    </Flex>
  );
}
